







































import Vue from "vue";
import { Component } from "vue-property-decorator";

@Component({
  components: {
  },
})
export default class History extends Vue {}
